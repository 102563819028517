/* eslint-disable */
import { useEffect, useState } from 'react';

interface IScrollToPosition {
  x?: number;
  y?: number;
  margin?: number;
  ref?: React.RefObject<HTMLElement>;
}

interface IUseOnScreen {
  rootMargin?: string;
  ref?: React.RefObject<HTMLElement>;
}

export function getTop({ y = 0, ref, margin = 0 }: IScrollToPosition): number {
  if (!ref?.current) return y;
  const scrollTop = window?.scrollY || document.documentElement.scrollTop;
  return ref.current.getBoundingClientRect().top + scrollTop + margin;
}

export function scrollToPosition({ x = 0, y = 0, ref, margin = 0 }: IScrollToPosition = {}) {
  if (typeof window === 'undefined') {
    return null;
  }

  const left = x;
  const top = getTop({ y, ref, margin });

  if ('scrollBehavior' in document.documentElement.style) {
    window.scrollTo({ left, top, behavior: 'smooth' });
  } else {
    window.scrollTo(left, top);
  }
}

export function useOnScreen({ ref, rootMargin = '0px' }: IUseOnScreen) {
  const [isIntersecting, setIsIntersecting] = useState(false);

  useEffect(() => {
    if (!ref?.current) return;

    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsIntersecting(entry.isIntersecting);
      },
      { rootMargin }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  return isIntersecting;
}
