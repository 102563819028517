import classNames from 'classnames';
import React from 'react';
import PropertyLocation from 'src/comps/Property/PropertyLocation';

import styles from './styles.module.css';

interface ILocationInfo {
  className?: string;
  address: string;
  city: string;
  distance: number;
  isInCenter: boolean;
  neighborhood: string;
  source: string;
}

export function LocationInfo(props: Readonly<ILocationInfo>) {
  const { className = '', address, city, distance, isInCenter, neighborhood, source } = props;

  const showLocation = source === 'results' || source === 'visited';

  const neighborhoodLocation = `${neighborhood}, ${city}`;
  let location = showLocation ? city : `${city}, ${address} `.trim();
  location = neighborhood ? neighborhoodLocation : location;

  return (
    <div className={classNames(styles.locationinfo, className)}>
      <PropertyLocation
        location={location}
        distance={distance}
        isInCenter={isInCenter}
        showLocation={showLocation}
      />
    </div>
  );
}
