import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import dynamic from 'next/dynamic';
import cn from 'classnames';

import { DiscountBadge } from 'src/comps/Atom/Badges/Discount';
import PricePerNight from './PricePerNight';
import TotalPrice from './TotalPrice';
import BeforePrice from './BeforePrice';

import Modal from 'client/components/Modal';

import styles from './styles.module.css';
import Loader from 'src/comps/Atom/Loader';

import { PriceDataType } from 'client/components/PriceDetailsFactory';
import Nights from './Nights';
import InfoButton from '../InfoButton';
import CircularProgress from 'src/comps/Atom/CircularProgress';

const PriceDetailsFactory = dynamic(() => import('client/components/PriceDetailsFactory'), {
  loading: () => <Loader />,
});

export enum FeaturedPrice {
  Total = 'total',
  PerNight = 'perNight',
}
export interface IPriceLayout {
  currency: string;
  discount?: number;
  nights?: number;
  priceFinal?: number | null;
  priceInitial?: number;
  pricePerNight?: number | null;
  vats?: React.ReactNode;
  priceData?: PriceDataType;
  prime?: boolean;
  showDiscount?: boolean;
  showFrom?: boolean;
  featuredPrice?: FeaturedPrice;
  loading?: boolean;
}

const PerNightPriceComp = ({
  featured = false,
  pricePerNight,
  currency,
}: {
  featured?: boolean;
  pricePerNight?: number | null;
  currency: string;
}) =>
  pricePerNight ? (
    <PricePerNight
      className={cn({ [styles.featuredPricePerNight]: featured })}
      pricePerNight={pricePerNight}
      currency={currency}
    />
  ) : null;

const TotalPriceComp = ({
  featured = false,
  priceInitial,
  priceFinal,
  currency,
  showDiscount,
}: {
  featured?: boolean;
  priceInitial?: number;
  priceFinal?: number | null;
  currency: string;
  showDiscount: boolean;
}) => (
  <>
    {showDiscount && priceInitial ? (
      <BeforePrice
        className={cn({ [styles.featuredBeforePrice]: featured })}
        beforePrice={priceInitial}
        currency={currency}
      />
    ) : null}
    {priceFinal ? (
      <TotalPrice
        className={cn({ [styles.featuredPrice]: featured })}
        totalPrice={priceFinal}
        currency={currency}
        title={false}
      />
    ) : null}
  </>
);

const PriceLayout = ({
  currency,
  discount,
  pricePerNight,
  priceInitial,
  priceFinal,
  vats,
  priceData,
  nights,
  prime = false,
  showDiscount = true,
  showFrom = false,
  featuredPrice = FeaturedPrice.PerNight,
  loading = false,
}: IPriceLayout) => {
  const intl = useIntl();
  const [openPriceDetail, setOpenPriceDetail] = useState(false);

  const handleOpenDetail = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setOpenPriceDetail(true);
  };

  return (
    <>
      <div className={styles.priceLayout}>
        <div className={cn(styles.priceRowTotal, { [styles.priceRowTotalFrom]: showFrom })}>
          {showDiscount && discount ? <DiscountBadge discount={discount} isPrime={prime} /> : null}
          {showFrom ? `${intl.formatMessage({ id: 'global.from' })}:` : null}
          <div className={styles.priceRow} data-testid="featured-price">
            {loading ? <CircularProgress data-testid="circular-progress" size="s" /> : null}
            {featuredPrice === FeaturedPrice.Total ? (
              <TotalPriceComp
                featured
                currency={currency}
                priceInitial={priceInitial}
                priceFinal={priceFinal}
                showDiscount={showDiscount}
              />
            ) : (
              <PerNightPriceComp pricePerNight={pricePerNight} currency={currency} featured />
            )}
            {priceData ? <InfoButton handleClick={handleOpenDetail} /> : null}
          </div>
        </div>

        <div className={styles.priceRow}>
          {featuredPrice === FeaturedPrice.Total ? (
            <PerNightPriceComp pricePerNight={pricePerNight} currency={currency} />
          ) : (
            <TotalPriceComp
              currency={currency}
              priceInitial={priceInitial}
              priceFinal={priceFinal}
              showDiscount={showDiscount}
            />
          )}
          {nights ? <Nights nights={nights} /> : null}
        </div>
        {vats && priceFinal ? <div className={styles.vats}>{vats}</div> : null}
      </div>
      {priceData ? (
        <Modal
          handleClose={() => setOpenPriceDetail(false)}
          show={openPriceDetail}
          title={intl.formatMessage({ id: 'global.price-breakdown' })}
          variant="newModal"
        >
          <PriceDetailsFactory {...priceData} variant="simple" isVoucher={false} />
        </Modal>
      ) : null}
    </>
  );
};

export default PriceLayout;
