import { defineMessages } from 'react-intl';

const messages = defineMessages({
  showMore: {
    id: 'global.show-more',
  },
  showLess: {
    id: 'global.show-less',
  },
  facilities: {
    id: 'global.facilities',
  },
  60: {
    id: 'global.facilities',
  },
  70: {
    id: 'global.facilities',
  },
  73: {
    id: 'facilities.entertainment',
  },
  74: {
    id: 'facilities.health',
  },
  90: {
    id: 'facilities.sports',
  },
  free: {
    id: 'global.free-label',
  },
  payment: {
    id: 'global.payment-label',
  },
  200: {
    id: 'facilities.group-transportation',
  },
  201: {
    id: 'facilities.group-bedrooms',
  },
  202: {
    id: 'facilities.group-bathrooms',
  },
  203: {
    id: 'facilities.group-entertainment',
  },
  204: {
    id: 'facilities.group-internet',
  },
  205: {
    id: 'facilities.group-parking',
  },
  206: {
    id: 'facilities.group-pool',
  },
  207: {
    id: 'facilities.group-beach',
  },
  208: {
    id: 'facilities.group-outdoors',
  },
  209: {
    id: 'facilities.group-services',
  },
  210: {
    id: 'facilities.group-convenience',
  },
  211: {
    id: 'facilities.group-accessibility',
  },
  212: {
    id: 'facilities.group-family',
  },
  213: {
    id: 'facilities.group-inclusivity',
  },
  214: {
    id: 'facilities.group-dining',
  },
  215: {
    id: 'facilities.group-food',
  },
  216: {
    id: 'facilities.group-business',
  },
  217: {
    id: 'facilities.group-activities',
  },
  218: {
    id: 'facilities.group-wellness',
  },
  219: {
    id: 'facilities.group-spa',
  },
  220: {
    id: 'global.facilities',
  },
  221: {
    id: 'facilities.group-safety',
  },
  222: {
    id: 'facilities.group-payment',
  },
  223: {
    id: 'facilities.group-certifications',
  },
});

export default messages;
