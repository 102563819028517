import React from 'react';
import { useIntl } from 'react-intl';

import PriceLayout, { FeaturedPrice } from '../../../PriceLayout';
import { OfferPriceProps } from '..';

export interface OfferPriceAllInProps extends OfferPriceProps {
  featuredPrice?: FeaturedPrice;
}

const OfferPriceAllIn = ({
  board,
  nights,
  priceData,
  prime = false,
  showDiscount,
  showFrom,
  featuredPrice = FeaturedPrice.Total,
  loading = false,
}: OfferPriceAllInProps) => {
  const intl = useIntl();

  const { currency, discount, pricePerNightWithFees, initialPrice, totalWithFees } = board;

  return (
    <PriceLayout
      currency={currency}
      discount={discount}
      pricePerNight={pricePerNightWithFees}
      priceInitial={initialPrice}
      priceFinal={totalWithFees}
      vats={intl.formatMessage({ id: 'net.price-total-taxes-included' })}
      priceData={priceData}
      prime={prime}
      showDiscount={showDiscount}
      showFrom={showFrom}
      nights={nights}
      featuredPrice={featuredPrice}
      loading={loading}
    />
  );
};

export default OfferPriceAllIn;
