import React from 'react';

import { usePrices } from 'src/hooks/usePrices';
import { PriceType } from 'src/types/prices';

import OfferPriceNet from './OfferPriceNet';
import OfferPriceNetTotal from './OfferPriceNetTotal';
import OfferPriceGross from './OfferPriceGross';
import OfferPriceAllIn from './OfferPriceAllIn';
import { PriceDataType } from 'client/components/PriceDetailsFactory';
import OfferPriceAllInDaily from './OfferPriceAllInDaily';

export interface IBoardPrice {
  currency: string;
  discount: number;
  finalPrice: number;
  initialPrice: number;
  pricePerNight: number;
  pricePerNightSubtotal: number;
  pricePerNightWithFees: number;
  subtotal: number;
  totalWithFees: number;
}

export interface OfferPriceProps {
  board: IBoardPrice;
  nights?: number;
  priceData?: PriceDataType;
  prime?: boolean;
  showFrom?: boolean;
  showDiscount?: boolean;
  loading?: boolean;
}

const pricesOptions = {
  [PriceType.Net]: OfferPriceNet,
  [PriceType.Gross]: OfferPriceGross,
  [PriceType.AllIn]: OfferPriceAllIn,
  [PriceType.AllInDaily]: OfferPriceAllInDaily,
  [PriceType.TrivagoNet]: OfferPriceNetTotal,
};

const OfferPrice = ({
  board,
  nights,
  priceData,
  prime,
  showFrom = false,
  showDiscount = true,
  loading = false,
}: OfferPriceProps) => {
  const { priceType } = usePrices({ rooms: [] });
  const PriceComponent = pricesOptions[priceType];

  return (
    <PriceComponent
      board={board}
      nights={nights}
      priceData={priceData}
      prime={prime}
      showDiscount={showDiscount}
      showFrom={showFrom}
      loading={loading}
    />
  );
};

export default OfferPrice;
