import React from 'react';
import cn from 'classnames';

import styles from './styles.module.css';

interface IBadgeBase {
  className?: string;
  children: React.ReactNode;
  testid?: string;
  style?: React.CSSProperties;
}

export function BadgeBase({
  className = '',
  children,
  testid = 'badge-base',
  style = undefined,
}: Readonly<IBadgeBase>) {
  return (
    <div data-testid={testid} className={cn(styles.badgeBase, className)} style={style}>
      {children}
    </div>
  );
}
