import React from 'react';
import { useIntl } from 'react-intl';

import PriceLayout, { FeaturedPrice } from '../../../PriceLayout';
import { OfferPriceProps } from '..';

const OfferPriceGross = ({
  board,
  nights,
  priceData,
  prime = false,
  showDiscount,
  showFrom,
  loading = false,
}: OfferPriceProps) => {
  const intl = useIntl();

  const { currency, discount, pricePerNight, initialPrice, finalPrice } = board;

  return (
    <PriceLayout
      currency={currency}
      discount={discount}
      pricePerNight={pricePerNight}
      priceInitial={initialPrice}
      priceFinal={finalPrice}
      vats={intl.formatMessage({ id: 'global.vat-included' })}
      priceData={priceData}
      prime={prime}
      showDiscount={showDiscount}
      showFrom={showFrom}
      nights={nights}
      featuredPrice={FeaturedPrice.Total}
      loading={loading}
    />
  );
};

export default OfferPriceGross;
