import React from 'react';
import IconInfo from 'client/icons/Info';

import styles from './styles.module.css';

const InfoButton = ({
  handleClick,
}: {
  handleClick?: (event: React.MouseEvent<HTMLElement>) => void;
}) => {
  return handleClick ? (
    <button type="button" className={styles.info} onClick={handleClick}>
      <IconInfo size={12} />
    </button>
  ) : (
    <i className={styles.info}>
      <IconInfo size={12} />
    </i>
  );
};

export default InfoButton;
