export const messagesMap: Record<string, { id: string }> = {
  'air-conditioner': {
    id: 'global.air-conditioner',
  },
  breakfast: {
    id: 'global.breakfast',
  },
  parking: {
    id: 'global.car-park',
  },
  gym: {
    id: 'global.gym',
  },
  pool: {
    id: 'global.pool',
  },
  indoor: {
    id: 'global.indoor-pool',
  },
  outdoor: {
    id: 'global.outdoor-pool',
  },
  receptionist: {
    id: 'global.24h-reception',
  },
  restaurant: {
    id: 'global.restaurant',
  },
  safe: {
    id: 'global.safebox',
  },
  terrace: {
    id: 'global.terrace',
  },
  wheelchair: {
    id: 'global.wheelchair',
  },
  wifi: {
    id: 'global.wifi',
  },
  freeCancellation: {
    id: 'global.free-cancellation',
  },
} as const;
