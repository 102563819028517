import React from 'react';

import Facility from 'client/components/Facilities/Facility';

import styles from './styles.module.css';
import cn from 'classnames';
import { messagesMap } from './messages';
import { useIntl } from 'react-intl';

interface Facility {
  name: string;
  icon: string;
}

interface IPropertyFacilities {
  className?: string;
  facilities?: Facility[];
}

export function PropertyFacilities({
  className = '',
  facilities = [],
}: Readonly<IPropertyFacilities>) {
  const intl = useIntl();

  return (
    <ul data-testid="property-facilities" className={cn(styles.base, className)}>
      {facilities.map(facility => {
        const message = messagesMap[facility.name];
        const name = message ? intl.formatMessage(message) : facility.name;

        return (
          <li key={facility.name}>
            <Facility {...facility} name={name} />
          </li>
        );
      })}
    </ul>
  );
}
