import dynamic from 'next/dynamic';
import React from 'react';

const Discount = dynamic(() => import('./Discount'));
const DiscountPrime = dynamic(() => import('./DiscountPrime'));

const DiscountTag = ({ discount, prime }: { discount: number; prime: boolean }) => {
  const DiscountComponent = prime ? DiscountPrime : Discount;
  return <DiscountComponent discount={discount} />;
};

export default DiscountTag;
