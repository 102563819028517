import Cookie from 'cookie';

import isArray from 'lib/functions/isArray';

import { getValidMarket } from './market';
import { getValidLanguage } from './language';
import { COUNTRIES, CUSTOM_DOMAINS, DEFAULT_COUNTRY } from 'src/constants/countries';

export function getCountryByMarket(market = DEFAULT_COUNTRY.market) {
  const country = Object.keys(COUNTRIES).find(key => COUNTRIES[key].market === market);
  return COUNTRIES[country];
}

export function getCountryByHost(host = 'www.stayforlong.com') {
  if (/www.stayforlong.com/.test(host)) {
    return DEFAULT_COUNTRY;
  }

  if (/de.stayforlong.com/.test(host) || /www.stayforlong.de/.test(host)) {
    return COUNTRIES.de;
  }

  const countryKey = Object.keys(COUNTRIES).find(country => {
    const subdomain = COUNTRIES[country].subdomain || 'www';
    const topdomain = COUNTRIES[country].topdomain || 'com';

    return host === `${subdomain}.stayforlong.${topdomain}`;
  });

  return COUNTRIES[countryKey] || DEFAULT_COUNTRY;
}

export function getKeyByMarket(market) {
  return Object.keys(COUNTRIES).find(country => COUNTRIES[country].market === market);
}

export function getKeyByMarketAndLanguage(market, language) {
  return Object.keys(COUNTRIES).find(
    country => COUNTRIES[country].market === market && COUNTRIES[country].language === language
  );
}

export function getCountry({ market, language } = {}) {
  const validMarket = getValidMarket({ market, language });
  const validLanguage = getValidLanguage({ market, language });

  const countryKey =
    getKeyByMarketAndLanguage(validMarket, validLanguage) || getKeyByMarket(validMarket) || 'zz';

  return { ...COUNTRIES[countryKey], market: validMarket, language: validLanguage };
}

function removeDuplicates(query) {
  return isArray(query) ? query[0] : query;
}

export function getMarketFromRequest({ req, query = {} }) {
  if (!req || !req.headers) {
    return DEFAULT_COUNTRY;
  }

  const { host } = req.headers;

  if (CUSTOM_DOMAINS.includes(host)) {
    return getCountryByHost(host);
  }

  const cookie = Cookie.parse(req.headers.cookie || '');
  const market = removeDuplicates(query.market || cookie.sfl_mkt);
  const language = removeDuplicates(query.lang || cookie.lang);

  if (market || language) {
    return getCountry({ market, language });
  }

  return DEFAULT_COUNTRY;
}
