import React from 'react';
import styles from './styles.module.css';
import Price from 'client/components/Price/Price';
import cn from 'classnames';

import { useIntl } from 'react-intl';

const TotalPrice = ({
  totalPrice,
  currency,
  title = true,
  className,
}: {
  totalPrice: number;
  currency: string;
  title?: boolean;
  className?: string;
}) => {
  const intl = useIntl();
  return (
    <div className={cn(styles.totalPrice, className)} data-testid="price-final">
      <Price amount={Math.round(totalPrice)} currency={currency} />{' '}
      {title ? <span>{intl.formatMessage({ id: 'global.total' })}</span> : null}
    </div>
  );
};

export default TotalPrice;
