import React from 'react';

import Stars from 'client/components/Stars';

import styles from './styles.module.css';
import cn from 'classnames';

interface ITitle {
  className?: string;
  name: string;
  stars: number;
}

export function Title({ className = '', name, stars }: Readonly<ITitle>) {
  return (
    <div data-testid="property-title" className={cn(styles.base, className)}>
      <h6 className={styles.title}>{name}</h6>
      <span className={styles.starsContainer}>
        <Stars stars={stars} size={12} />
      </span>
    </div>
  );
}
