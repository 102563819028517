import React from 'react';
import cn from 'classnames';

import styles from './styles.module.css';
import Price from 'client/components/Price/Price';

import { useIntl } from 'react-intl';

const PricePerNight = ({
  pricePerNight,
  className,
  currency,
}: {
  pricePerNight: number;
  className?: string;
  currency: string;
}) => {
  const intl = useIntl();
  return (
    <div className={cn(styles.pricePerNight, className)} data-testid="price-per-night">
      {intl.formatMessage(
        { id: 'global.price-night' },
        {
          price: (
            <span>
              <Price amount={Math.round(pricePerNight)} currency={currency} />
            </span>
          ),
        }
      )}
    </div>
  );
};

export default PricePerNight;
