import { DEFAULT_COUNTRY } from 'src/constants/countries';

export const LANGUAGES_FOR_SUPPORTED_MARKETS = {
  ar: 'es', // Argentina
  at: 'de', // Austria
  be: 'fr', // Belgium
  br: 'pt', // Brazil
  ch: 'de', // Switzerland
  cl: 'es', // Chile
  co: 'es', // Colombia
  de: 'de', // Germany
  dk: 'da', // Denmark
  ec: 'es', // Ecuador
  es: 'es', // Spain
  fi: 'fi', // Finland
  fr: 'fr', // France
  gb: 'en', // United Kingdom
  gr: 'el', // Greece
  ie: 'en', // Ireland
  it: 'it', // Italy
  mx: 'es', // Mexico
  nl: 'nl', // Netherlands
  no: 'nn', // Norway
  pe: 'es', // Peru
  pl: 'pl', // Poland
  pt: 'pt', // Portugal
  ro: 'ro', // Romania
  se: 'sv', // Sweden
  sk: 'sk', // Slovakia
  tr: 'tr', // Turkey
  uk: 'en', // United Kingdom
  uy: 'es', // Uruguay
  za: 'en', // South Africa
};

export const UNSUPPORTED_LANGUAGES = {
  ru: 'ru', // Russian
};

export function getValidLanguage({ language, market } = {}) {
  const REGEX_LANGUAGE = /^[a-zA-Z]{2}$/g;

  if (language && REGEX_LANGUAGE.test(language) && !UNSUPPORTED_LANGUAGES[language]) {
    return language;
  }

  if (market && LANGUAGES_FOR_SUPPORTED_MARKETS[market]) {
    return LANGUAGES_FOR_SUPPORTED_MARKETS[market];
  }

  return DEFAULT_COUNTRY.language;
}
