import { BOARD_CODE_ROOM_ONLY } from 'client/components/Rooms/constants';
import { MAX_FACILITIES } from './constants';
import { Facility } from '.';
import { getLinkPropsFromUrl } from 'lib/utils';

export function getPerks(
  board: string,
  boardCode: string,
  cancellation: boolean,
  facilities: Facility[] = []
) {
  const perks =
    facilities.length > MAX_FACILITIES ? facilities.slice(0, MAX_FACILITIES) : facilities;

  if (board && boardCode !== BOARD_CODE_ROOM_ONLY) {
    perks[1] = { name: board, icon: 'board' };
  }

  if (cancellation) {
    perks[2] = { name: 'freeCancellation', icon: 'checked' };
  }

  return perks;
}

export function buildLinkProps({
  url,
  country,
  slug,
  citySlug,
  language,
}: {
  url?: string;
  country: string;
  slug: string;
  citySlug: string;
  language: string;
}) {
  if (url) {
    return getLinkPropsFromUrl(url);
  }

  return {
    country,
    slug,
    city: citySlug,
    search: {
      clickin: 'search',
      lang: language,
      content: 'list',
    },
  };
}
