import React from 'react';

import { useBusiness } from 'src/hooks/useBusiness';
import { calculateDiscount } from 'client/components/Discount/utils';
import { DiscountType } from './types';
import { DiscountPromo } from './Promo';
import { DiscountPrime } from './Prime';
import { DiscountDefault } from './Default';

const discountComponentMap = {
  [DiscountType.Promo]: DiscountPromo,
  [DiscountType.Prime]: DiscountPrime,
  [DiscountType.Default]: DiscountDefault,
};

const getType = (isPrime: boolean, promo: boolean) => {
  if (promo) {
    return DiscountType.Promo;
  }
  if (isPrime) {
    return DiscountType.Prime;
  }
  return DiscountType.Default;
};

export interface IDiscount {
  discount: number | null;
}

interface IDiscountBadge {
  price?: number;
  priceBefore?: number;
  discount?: number;
  isPrime?: boolean;
}

export function DiscountBadge({
  discount,
  price,
  priceBefore,
  isPrime = false,
}: Readonly<IDiscountBadge>) {
  const { promo } = useBusiness();
  const discountType = getType(isPrime, !!promo);
  const DiscountComponent = discountComponentMap[discountType];

  if (discount) {
    return <DiscountComponent discount={discount} />;
  }

  const calculatedDiscount = calculateDiscount(price, priceBefore);

  return <DiscountComponent discount={calculatedDiscount} />;
}
