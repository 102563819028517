import React from 'react';
import { useRouter } from 'next/router';
import cn from 'classnames';
import { noop } from 'lodash';
import Link from 'next/link';
import { useIntl } from 'react-intl';

import { useBusiness } from 'src/hooks/useBusiness';
import Photo from 'client/components/Photo';
import { AFFILIATE_LIST, AFFILIATE_NAMES } from 'src/constants/affiliates';
import { DEFAULT_SOURCE } from 'src/constants/sources';
import { HighlightedText } from 'src/comps/Property/styles';
import { ImageApiObject } from 'src/types/image';
import OfferPrice, {
  IBoardPrice,
} from 'client/components/Rooms/RoomResponsive/OfferResponsive/OfferPrice';
import { useMarket } from 'src/hooks/useMarket';
import { getResponsiveImgProps } from 'src/comps/Gallery/utils';
import { calculateDiscount } from 'client/components/Discount/utils';
import DiscountTag from 'client/components/DiscountTag';
import CarouselCss from 'src/comps/CarouselCss';

import { buildLinkProps, getPerks } from './utils';
import { PropertyFacilities } from './Facilities';
import { DiscountBadge } from '../../Atom/Badges/Discount';
import { ScoreReviews } from './Score';
import { SouldOutMessage } from './SouldOutMessage';
import { PropertyCTA } from './PropertyCTA';
import { Title } from './Title';
import { MobileOnlyBadge } from '../../Atom/Badges/MobileOnly';
import { LocationInfo } from './LocationInfo';

import styles from './styles.module.css';
import { ButtonRemove } from './ButtonRemove';

export enum PropertyCardVariants {
  'default',
  'lite',
  'fixedHeight',
}

export enum PropertySources {
  Results = 'results',
  Related = 'related',
  HotelsSeen = 'hotelsSeen',
  Visited = 'visited',
}

const imageSizes = {
  desktop: {
    size: '400px',
    srcWidth: 400,
  },
  tablet: {
    size: '400px',
    srcWidth: 400,
  },
  mobile: {
    size: '400px',
    srcWidth: 400,
  },
};

export type Facility = {
  name: string;
  icon: string;
  featured?: boolean;
};

interface IPropertyCard {
  className?: string;
  address: string;
  board: string;
  boardCode: string;
  cancellation: string;
  city: string;
  citySlug: string;
  comments: number;
  country: string;
  currency: string;
  distance?: number;
  facilities: Facility[];
  handleSwipeEvent: () => void;
  highlighted?: boolean;
  id: string;
  isInCenter: boolean;
  isLoadingContent?: boolean;
  isMobile?: boolean;
  isPrime: boolean;
  variant?: PropertyCardVariants;
  name: string;
  nights: number;
  neighborhood: string;
  newPage: boolean;
  onClick: () => void;
  onRemove?: () => void;
  photos: ImageApiObject[];
  position?: number;
  price: number;
  priceOld: number;
  pricePerNight: number;
  pricePerNightSubtotal: number;
  pricePerNightWithFees: number;
  priceWithFees: number;
  score: number;
  slug: string;
  source: PropertySources;
  stars: number;
  subtotal: number;
  url?: string;
}

export function PropertyCard(props: Readonly<IPropertyCard>) {
  const {
    address,
    board,
    boardCode,
    cancellation,
    className = '',
    city,
    citySlug,
    comments,
    country,
    currency,
    distance = 0,
    facilities,
    handleSwipeEvent = noop,
    highlighted = false,
    id,
    isInCenter,
    isLoadingContent = false,
    isMobile = false,
    isPrime,
    name,
    nights,
    neighborhood,
    newPage = true,
    onClick = noop,
    onRemove = noop,
    photos,
    position = 1,
    price,
    priceOld,
    pricePerNight,
    pricePerNightSubtotal,
    pricePerNightWithFees,
    priceWithFees,
    score = 0,
    slug,
    source = PropertySources.Results,
    stars,
    subtotal: priceSubtotal,
    url,
    variant = PropertyCardVariants.default,
  } = props;

  const intl = useIntl();
  const { market, language } = useMarket();
  const { affiliate } = useBusiness();
  const router = useRouter();
  const { source: sourceParam } = router.query;

  const perks = getPerks(board, boardCode, !!cancellation, facilities);

  const isAffiliate = AFFILIATE_LIST.includes(affiliate);
  const isDefaultSource = sourceParam === DEFAULT_SOURCE;
  const isFoundOn = highlighted && isAffiliate && sourceParam && !isDefaultSource;
  const isSoldOut =
    !price && source !== PropertySources.HotelsSeen && source !== PropertySources.Visited;
  const showPrice = price && source !== PropertySources.HotelsSeen;
  const showRemoveButton = [PropertySources.HotelsSeen, PropertySources.Visited].includes(source);

  const discount = calculateDiscount(price, priceOld);

  const boardPrice: IBoardPrice = {
    currency,
    discount: discount ?? 0,
    finalPrice: price,
    initialPrice: priceOld,
    pricePerNight,
    pricePerNightSubtotal,
    pricePerNightWithFees,
    subtotal: priceSubtotal,
    totalWithFees: priceWithFees,
  };

  const {
    country: propertyCountry,
    slug: propertySlug,
    city: propertyCity,
  } = buildLinkProps({ url, country, slug, citySlug, language });

  const {
    query: { aid, subtotal, taxes, total, offer_type: offerType },
  } = router;

  const href = {
    pathname: '/hotel/[country]/[slug]',
    query: {
      market,
      lang: language,
      country: propertyCountry,
      slug: `${propertySlug}_${propertyCity}`,
      internal_navigation: 'true',
      ...(aid &&
        highlighted &&
        sourceParam !== DEFAULT_SOURCE && {
          aid: aid as string,
          ...(sourceParam && { source: sourceParam as string }),
          ...(subtotal && { subtotal: subtotal as string }),
          ...(taxes && { taxes: taxes as string }),
          ...(total && { total: total as string }),
          ...(offerType && { offer_type: offerType as string }),
        }),
    },
  };

  const isFixedHeightVariant = variant === PropertyCardVariants.fixedHeight;

  const openInNewPage = newPage && variant !== PropertyCardVariants.lite && source !== 'related';

  return (
    <div
      data-tracking-item-position={position}
      data-testid={source || 'property'}
      className={cn(
        className,
        styles.wrapper,
        { [styles.foundOn]: isFoundOn },
        { [styles.highlighted]: highlighted },
        { [styles.isSoldOut]: isSoldOut },
        { [styles.lite]: variant === PropertyCardVariants.lite }
      )}
    >
      {isFoundOn && (
        <HighlightedText color="grey8" data-testid="highlighted-aid">
          &#128071; {intl.formatMessage({ id: 'global.found-on' })}
          &nbsp;<span>{AFFILIATE_NAMES[affiliate as keyof typeof AFFILIATE_NAMES]}</span>
        </HighlightedText>
      )}

      <div
        className={cn(
          styles.base,
          { [styles.baseHighlighted]: highlighted },
          { [styles.fixedHeight]: isFixedHeightVariant }
        )}
      >
        <div
          className={cn(styles.mediaContainer, {
            [styles.mediaContainerFixed]: isFixedHeightVariant,
          })}
        >
          <CarouselCss
            className={cn(styles.propertyPhotos, {
              [styles.fixedHeightCarousel]: isFixedHeightVariant,
            })}
            items={photos}
            prefix={id}
            indicator
            handleSwipeEvent={handleSwipeEvent}
          >
            {photo => {
              const photoWithProps = getResponsiveImgProps(photo as ImageApiObject, imageSizes);
              return (
                <Link
                  href={href}
                  target={openInNewPage ? '_blank' : undefined}
                  onClick={onClick}
                  legacyBehavior={false}
                >
                  <Photo
                    deSaturated={false}
                    lazyLoad
                    name={name}
                    photo={photoWithProps.src}
                    isResponsive
                    srcSet={photoWithProps.srcSet}
                    sizes={photoWithProps.sizes}
                  />
                </Link>
              );
            }}
          </CarouselCss>
          <div className={styles.discountTag}>
            <DiscountTag
              price={price}
              priceBefore={priceOld}
              prime={isPrime}
              isLoading={isLoadingContent}
            />
          </div>
          {showRemoveButton && (
            <ButtonRemove onClick={() => onRemove({ propertyId: parseInt(id, 10) })} />
          )}
        </div>
        <div className={styles.right}>
          <Link
            className={cn(styles.linkContent, {
              [styles.fixedHeightLinkContent]: isFixedHeightVariant,
            })}
            target={openInNewPage ? '_blank' : undefined}
            href={href}
            onClick={onClick}
            legacyBehavior={false}
          >
            &nbsp;
          </Link>
          <header className={styles.header}>
            <Title
              name={name}
              stars={stars}
              className={isFixedHeightVariant ? styles.fixedHeightTitle : undefined}
            />
            <LocationInfo
              address={address}
              city={city}
              className={styles.locationInfo}
              distance={distance}
              isInCenter={isInCenter}
              neighborhood={neighborhood}
              source={source}
            />
          </header>

          <main className={styles.main}>
            <div className={styles.servicePrices}>
              <div>
                <ScoreReviews className={styles.score} score={score} comments={comments} />
                {isSoldOut && (
                  <div className={styles.soldOutContainer}>
                    <SouldOutMessage />
                  </div>
                )}
                {!isSoldOut && (
                  <PropertyFacilities
                    className={cn(styles.facilities, {
                      [styles.fixedHeightFacilities]: isFixedHeightVariant,
                    })}
                    facilities={perks}
                  />
                )}
              </div>

              <div className={styles.footer}>
                {!isSoldOut && showPrice && (
                  <>
                    {variant !== PropertyCardVariants.fixedHeight && (
                      <div className={styles.discountTagMobile}>
                        {isMobile && <MobileOnlyBadge />}
                        <DiscountBadge price={price} priceBefore={priceOld} isPrime={isPrime} />
                      </div>
                    )}
                    <OfferPrice
                      board={boardPrice}
                      nights={nights}
                      prime={isPrime}
                      showDiscount={false}
                      showFrom
                    />
                  </>
                )}

                <PropertyCTA
                  className={styles.ctaSamePage}
                  onClick={onClick}
                  url={href}
                  isSoldOut={isSoldOut}
                  newPage={false}
                />
                <PropertyCTA
                  className={styles.ctaNewPage}
                  onClick={onClick}
                  url={href}
                  isSoldOut={isSoldOut}
                />
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
}
