import React from 'react';

import Discount from 'client/components/Rooms/RoomResponsive/PriceLayout/DiscountTag';

import { BadgeBase } from '../..';
import { IDiscount } from '..';

import styles from './styles.module.css';

export function DiscountDefault({ discount }: Readonly<IDiscount>) {
  if (!discount) return null;

  return (
    <BadgeBase testid="discount-badge" className={styles.base}>
      <Discount discount={discount} prime={false} />
    </BadgeBase>
  );
}
