import React from 'react';
import { FormattedMessage } from 'react-intl';

import IconFacility from 'client/icons/facilities/Facility';
import messages from './messages';

import styles from './styles.module.css';

interface IFacility {
  icon?: string;
  name: string;
  free?: boolean;
  featured?: boolean;
}

const Facility: React.FC<IFacility> = ({ icon = 'done', name, free, featured }) => (
  <div className={styles.facilityContainer}>
    <IconFacility name={icon} />
    <span>
      {name}
      {free && featured && <FormattedMessage {...messages.free} tagName="span" />}
    </span>
  </div>
);

export default Facility;
