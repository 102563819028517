import React from 'react';

import InfoCircle from 'client/icons/Info';
import { useIntl } from 'react-intl';

import styles from './styles.module.css';

export function SouldOutMessage() {
  const intl = useIntl();
  return (
    <div data-testid="soldout-message" className={styles.base}>
      <InfoCircle size={24} />
      <span className={styles.infoText}>
        {intl.formatMessage({ id: 'global.dates-not-available' })}
      </span>
    </div>
  );
}
