import { DEFAULT_COUNTRY } from 'src/constants/countries';

export const MARKETS_FOR_SUPPORTED_LANGUAGES = {
  de: 'de',
  da: 'dk',
  el: 'gr',
  en: 'uk',
  es: 'es',
  fi: 'fi',
  fr: 'fr',
  it: 'it',
  nl: 'nl',
  nn: 'no',
  pl: 'pl',
  pt: 'pt',
  ro: 'ro',
  sk: 'sk',
  sv: 'se',
  tr: 'tr',
};

export function getValidMarket({ market, language } = {}) {
  const REGEX_MARKET = /^[a-zA-Z]{2}$/g;

  if (market && REGEX_MARKET.test(market)) {
    return market;
  }

  if (language && MARKETS_FOR_SUPPORTED_LANGUAGES[language]) {
    return MARKETS_FOR_SUPPORTED_LANGUAGES[language];
  }

  return DEFAULT_COUNTRY.market;
}
