import React from 'react';

import IconTrash from 'client/icons/Trash';

import styles from './styles.module.css';

interface ButtonRemoveProps {
  readonly onClick: () => void;
}

export function ButtonRemove({ onClick }: ButtonRemoveProps) {
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();
    onClick();
  };
  return (
    <button
      type="button"
      data-testid="button-remove"
      className={styles.button}
      onClick={handleClick}
    >
      <IconTrash size={18} />
    </button>
  );
}
