import React from 'react';
import { useIntl } from 'react-intl';
import cn from 'classnames';

import { getScoreRange } from 'client/components/Score/utils';
import messages from 'client/components/RatingContent/messages';
import { Messages } from 'src/types/messages';

import styles from './styles.module.css';
import Score from 'client/components/Score';
import { SIZE_SMALL } from 'client/components/Score/constants';

interface IScoreReviews {
  score: number;
  comments: number;
  className?: string;
}

export function ScoreReviews({ score, comments, className = '' }: Readonly<IScoreReviews>) {
  const intl = useIntl();

  if (!score || !comments || comments === 0) return null;
  const category = getScoreRange(score);

  const ratingMessages: Messages = messages;
  const scoreMessage = intl.formatMessage(ratingMessages[`score${category.level}`]);
  const levelStyle = styles[`level-${category.level}`];
  const catClassName = `${styles.category} ${levelStyle}`;

  const numberFormatter = new Intl.NumberFormat(intl.locale, { useGrouping: true });

  return (
    <div data-testid="score-reviews" className={cn(styles.base, className)}>
      <Score value={score} size={SIZE_SMALL} />
      <div className={styles.scoreAndComments}>
        <div className={catClassName}>{scoreMessage}</div>
        <div className={styles.comments}>
          {numberFormatter.format(comments)} {intl.formatMessage(ratingMessages.reviews)}
        </div>
      </div>
    </div>
  );
}
