import React from 'react';
import { useIntl } from 'react-intl';

const Nights = ({ nights }: { nights: number }) => {
  const intl = useIntl();
  const valueNights = { nights };

  return <>({intl.formatMessage({ id: 'global.nights' }, valueNights)})</>;
};

export default Nights;
