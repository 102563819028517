import React from 'react';
import { CSSProperties } from 'styled-components';

import { useBusiness } from 'src/hooks/useBusiness';
import { BadgeBase } from '../..';
import { useIntl } from 'react-intl';
import { IDiscount } from '..';

import styles from './styles.module.css';

export function DiscountPromo({ discount }: Readonly<IDiscount>) {
  const intl = useIntl();
  const { promo } = useBusiness();

  if (!promo) return null;

  return (
    <BadgeBase
      testid="discount-badge-promo"
      className={styles.base}
      style={
        {
          '--badge-color': promo.global.tags.color,
          '--badge-bg-color': promo.global.tags.background,
        } as CSSProperties
      }
    >
      {intl.formatMessage({ id: promo.global.tags.textId })}
      {discount && intl.formatMessage({ id: 'discount.today' }, { discount: `: -${discount}%` })}
    </BadgeBase>
  );
}
