import React from 'react';

import IconMobilePhone from 'client/icons/MobilePhone';
import { useIntl } from 'react-intl';

import styles from './styles.module.css';
import { BadgeBase } from '..';

export function MobileOnlyBadge() {
  const intl = useIntl();
  return (
    <BadgeBase testid="mobile-only-badge" className={styles.base}>
      <IconMobilePhone size={10} />
      {intl.formatMessage({ id: 'global.mobile-only-price' })}
    </BadgeBase>
  );
}
