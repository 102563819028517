import React from 'react';
import { useIntl } from 'react-intl';

import Button from 'src/comps/Atom/Buttons/Button';
import ChevronRight from 'client/icons/ChevronRight';
import InfoCircle from 'client/icons/Info';
import Link from 'next/link';
import { noop } from 'lodash';

import styles from './styles.module.css';

interface IPropertyCTA {
  className?: string;
  url: { pathname: string; query: { [key: string]: string } };
  isSoldOut: boolean;
  newPage?: boolean;
  onClick?: () => void;
}

export function PropertyCTA({
  className = '',
  url,
  isSoldOut,
  newPage = true,
  onClick = noop,
}: Readonly<IPropertyCTA>) {
  const intl = useIntl();
  return (
    <span className={`${className} ${styles.propertyCTA}`}>
      <Link href={url} passHref>
        <Button
          data-testid={`property-cta${newPage ? '-newpage' : ''}`}
          as="a"
          onClick={onClick}
          target={newPage ? '_blank' : undefined}
          backgroundColor="brand1"
          borderColor="brand1"
          color="grey8"
          size="s"
          disabled={isSoldOut}
          leftIcon={isSoldOut ? <InfoCircle /> : null}
          rightIcon={!isSoldOut ? <ChevronRight /> : null}
        >
          {intl.formatMessage({
            id: isSoldOut ? 'global.not-available' : 'global.available',
          })}
        </Button>
      </Link>
    </span>
  );
}
