import React from 'react';
import styles from './styles.module.css';
import Price from 'client/components/Price/Price';

import cn from 'classnames';

const BeforePrice = ({
  beforePrice,
  currency,
  className,
}: {
  beforePrice: number;
  currency: string;
  className?: string;
}) => {
  return (
    <div className={cn(styles.beforePrice, className)} data-testid="price-initial">
      <Price amount={Math.round(beforePrice)} currency={currency} />
    </div>
  );
};

export default BeforePrice;
