import React from 'react';

import { BadgeBase } from '../..';
import { useIntl } from 'react-intl';
import { IDiscount } from '..';

import styles from './styles.module.css';

export function DiscountPrime({ discount }: Readonly<IDiscount>) {
  const intl = useIntl();
  return (
    <BadgeBase testid="discount-badge-prime" className={styles.prime}>
      {intl.formatMessage({ id: 'global.prime' })}
      {discount && intl.formatMessage({ id: 'discount.today' }, { discount: `: -${discount}%` })}
    </BadgeBase>
  );
}
