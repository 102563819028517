import React from 'react';

import { FeaturedPrice } from '../../../PriceLayout';
import OfferPriceAllIn from '../OfferPriceAllIn';
import { OfferPriceProps } from '..';

const OfferPriceAllInDaily = (props: OfferPriceProps) => {
  return <OfferPriceAllIn {...props} featuredPrice={FeaturedPrice.PerNight} />;
};

export default OfferPriceAllInDaily;
